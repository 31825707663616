import {
  Container,
  Typography,
  Grid,
  Box,
  Avatar,
  CardContent,
  Link,
  Paper,
  IconButton,
  CssBaseline,
  AppBar,
  Toolbar
} from "@mui/material";
import logo from './img/logo.jpg';
import Image from './img/aboutUsBackground.jpg';
import Video from './img/Sylberize_Video.mp4';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import GoogleIcon from '@mui/icons-material/Google';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';

const teamMembers = [
  {
    name: "Alexander Enzinger",
    role: "Project Sponsor",
    image:
      "/imgs/aboutUs/alexander-enzinger.jpg",
    description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  },
  {
    name: "Colton Weatherston",
    role: "Back End Developer",
    image:
      "/imgs/aboutUs/colton-weatherston.jpg",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  },
  {
    name: "Reacher Dang",
    role: "Project Lead & Front End Developer",
    image:
      "/imgs/aboutUs/reacher-dang.jpg",
    description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  },
];

function App() {
  return (
    <div className="App">
      <Box
        sx={{ height: "100vh", display: "flex", flexDirection: "column" }}
      >
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          component="nav"
          position="sticky"
          sx={{ backgroundColor: "black" }}
        >
          <Toolbar>
            <Box
              component="img"
              sx={{
                height: 40,
                marginRight: 2,
              }}
              alt="Logo"
              src={logo}
            />
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
            >
              Sylberize
            </Typography>
          </Toolbar>
        </AppBar>
      </Box>

        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >

        <Box
          sx={{
            backgroundImage: `url(${Image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            py: 4,
            height: "80vh",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Container maxWidth="lg">
            <Grid container spacing={4} alignItems="center">
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    p: 4,
                    borderRadius: 2,
                    color: "white",
                  }}
                >
                  <Typography variant="h3" component="h1" gutterBottom align="left">
                    Our Vision
                  </Typography>
                  <Typography variant="body1" paragraph align="left">
                    Welcome to our platform, dedicated to empowering local service
                    providers. We offer a space for small business owners to showcase
                    their work and connect with local customers effectively. Our booking
                    system simplifies schedule management and includes online billing to
                    protect the interests of both customers and businesses. Our goal is to
                    provide a user-friendly experience that helps providers and customers
                    engage seamlessly.
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <video width="100%" controls>
                    <source src={Video} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Container maxWidth="lg">
          <Box sx={{ my: 4 }}>
            <Typography
              variant="h4"
              component="h2"
              gutterBottom
              align="center"
              sx={{ mt: 0, mb: 6 }}
            >
              Our Team
            </Typography>
            <Grid container>
              {teamMembers.map((member, index) => (
                <Grid container item xs={12} key={index} direction={index % 2 === 0 ? "row" : "row-reverse"} alignItems="center"
                  sx={{
                    background: index % 2 === 0 ? 'linear-gradient(to right, #e0e0e0, #ffffff)' : 'linear-gradient(to right, #ffffff, #e0e0e0)',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    py: 4,
                    px: 2,
                    borderRadius: 3,
                    mb: 4,
                    position: 'relative',
                    overflow: 'hidden'
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: 'rgba(255, 255, 255, 0.8)',
                      position: 'absolute',
                      top: 0,
                      bottom: 0,
                      left: 0,
                      right: 0,
                      zIndex: 1,
                    }}
                  />
                  <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'center', zIndex: 2 }}>
                    <Avatar alt={member.name} src={member.image} sx={{ width: 180, height: 180, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }} />
                  </Grid>
                  <Grid item xs={12} md={9} sx={{ zIndex: 2 }}>
                    <Box sx={{ p: 2 }}>
                      <CardContent>
                        <Typography variant="h5" component="div" sx={{ fontWeight: 'bold', color: '#333' }}>
                          {member.name}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" sx={{ fontStyle: 'italic', color: '#555' }}>
                          {member.role}
                        </Typography>
                        <Typography variant="body2" paragraph sx={{ fontSize: '1.1rem', color: '#666' }}>
                          {member.description}
                        </Typography>
                      </CardContent>
                    </Box>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Container>

        </Box>

        <Paper 
              sx={{ mt: 'auto', p: 2, textAlign: 'center', backgroundColor: 'black'}}
          >
              <Grid
                  container
                  justifyContent="center"
                  sx={{
                      backgroundColor: "white",
                      borderRadius: 2,
                      maxWidth: 'fit-content',
                      maxHeight: 'fit-content',
                      margin: 'auto'
                  }}
              >

                  <Grid item>
                      <Link href="#" color="inherit">
                          <IconButton>
                              <FacebookIcon sx={{ color: '#3b5998' }} />
                          </IconButton>
                      </Link>
                  </Grid>
                  <Grid item>
                      <Link href="#" color="inherit">
                          <IconButton>
                              <TwitterIcon sx={{ color: '#55acee' }} />
                          </IconButton>
                      </Link>
                  </Grid>
                  <Grid item>
                      <Link href="#" color="inherit">
                          <IconButton>
                              <GoogleIcon sx={{ color: '#dd4b39' }} />
                          </IconButton>
                      </Link>
                  </Grid>
                  <Grid item>
                      <Link href="#" color="inherit">
                          <IconButton>
                              <InstagramIcon sx={{ color: '#ac2bac' }} />
                          </IconButton>
                      </Link>
                  </Grid>
                  <Grid item>
                      <Link href="#" color="inherit">
                          <IconButton>
                              <LinkedInIcon sx={{ color: '#0082ca' }} />
                          </IconButton>
                      </Link>
                  </Grid>
                  <Grid item>
                      <Link href="#" color="inherit">
                          <IconButton>
                              <GitHubIcon sx={{ color: '#0082ca' }} />
                          </IconButton>
                      </Link>
                  </Grid>
              </Grid>
              <Typography variant="body2" color="text.secondary" sx={{ mt: 2, color: 'white' }}>
                  Contact: <Link color="inherit" href="mailto:info@sylberize.com">info@sylberize.com</Link>
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mt: 2, color: 'white' }}>
                  © 2025 Copyright: <Link color="inherit" href="#">sylberize.com</Link>
              </Typography>
          </Paper>

        </Box>
    </div>
  );
}

export default App;
